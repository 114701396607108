@use "./scss/colors" as C;
@use "./scss/layout" as L;

.loader {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* START - Fancy scrollbar maggle */
/* Let's get this party started */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: C.$secondary;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:window-inactive {
	background: C.$secondary;
	border-radius: 05px;
}

/* OVERWRITE Material-Ui */
.MuiGridListTile-tile {
	border-radius: 4px;
}

.MuiToolbar-gutters {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
